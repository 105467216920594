module.exports = {
    it: {
        path: 'privacy',
        title: 'Privacy - Tappezzeria Orsilli',
        description: 'Art. 13 del Regolamento europeo (UE) 2016/679 (di seguito GDPR) relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla circolazione di tali dati, comunichiamo quanto riportato nella pagina.'
    },
    en: {
        path: 'privacy',
        title: 'Privacy - Tappezzeria Orsilli',
        description: 'Art. 13 del Regolamento europeo (UE) 2016/679 (di seguito GDPR) relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla circolazione di tali dati, comunichiamo quanto riportato nella pagina.'
    },
    component: 'informative/privacy'
}
