import React from "react";
import ReactDOM from "react-dom";
import 'aos/dist/aos.css';
import "./src/assets/scss/app.scss";
import { GlobalProvider } from "./src/context/GlobalContext";
import { RootProvider } from "./src/context/RootContext";
window.cc = require('vanilla-cookieconsent')
const axios = require("axios").default

export const onInitialClientRender = () => {
    axios.defaults.baseURL = process.env.GATSBY_API_URL
    axios.defaults.headers.common = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "X-Token-Mailer": process.env.GATSBY_MAILER_TOKEN
    };
    window.gtag = window.gtag || function(){};
}
export const wrapPageElement = ({ element, props }) => {
  return <GlobalProvider {...props}>
      {element}
  </GlobalProvider>;
};

export const wrapRootElement = ({ element }) => {
  return <RootProvider>
      {element}
  </RootProvider>;
};

const loadableReady = require("@loadable/component").loadableReady;
export const replaceHydrateFunction = () => {
    return (element, container, callback) => {
       loadableReady(() => {
         ReactDOM.render(element, container, callback);
       });
    };
};
