import RootContext from "./RootContext";
import React, {useContext, useEffect} from "react";

import PropTypes from "prop-types";
import {graphql, useStaticQuery} from "gatsby";
import find from "lodash/find"
import get from "lodash/get"
import each from "lodash/each"
const GlobalContext = React.createContext();

const GlobalProvider = ({children, pageContext}) => {
    const gatsbyQuery = useStaticQuery(graphql`
    query {
      allTranslations {
        edges {
          node {
            lang
            messages {
              key
              message
            }
          }
        }
      }
      allRoutes {
        edges {
          node {
            route
            options {
              en {
                path
              }
              it {
                path
              }
            }
          }
        }
      }
    }
  `);

    const rContext = useContext(RootContext);
    useEffect(() => {
        rContext.router.setCurrent(pageContext);
    }, [pageContext, rContext]);

    let langFormat = "";
    switch (pageContext.locale) {
        default:
            langFormat = "dddd DD MMMM YYYY";
            break;
        case "en":
            langFormat = "dddd MMMM DD YYYY";
            break;
        case "es":
            langFormat = "dddd, DD MMMM, YYYY";
            break;
    }
    const isCurrentRoute = (name, lang) => {
        return pageContext.route === name && (lang ? (pageContext.locale === lang) : true)
    }

    const url = (to, lang = "") => {
        const key = to.toLowerCase();
        lang = lang || pageContext.locale;
        if (key === "index") {
            return rContext.singleLang ? '/' : `/${lang}/`;
        }
        const route = find(
            gatsbyQuery?.allRoutes?.edges || [],
            (r) => r.node.route === key
        ) || {node: {}};
        const path = get(route, `node.options.${lang}.path`, false);

        if (!path) {
            return "#";
        } else if (path.slice(-1, 1) !== "/") {
            return rContext.singleLang ? `/${path}/` : `/${lang}/${path}/`;
        } else {
            return rContext.singleLang ? `/${path}` : `/${lang}/${path}`;
        }
    };

    const fParams = (str, params, log) => {
        each(params, (value, key) => {
            str = str.replace(`{{${key}}}`, value);
        });
        return str;
    };
    const f = (key, params = null, defaultValue = '', locale = null, log = false) => {
        const lang = locale || pageContext.locale;
        const selectedLang = find(
            gatsbyQuery?.allTranslations?.edges || [],
            (r) => r.node.lang === lang
        ) || {node: {}};

        const message = find(
            selectedLang.node.messages,
            (m) => m.key === key
        ) || {};
        const translated = get(message, `message`, false);
        if (!translated) {
            if (rContext.isDevelop()) {
                //console.log((defaultValue || key) + "<!M!>")
            }
            return (defaultValue || key) + "<!M!>";
        }
        const result = params ? fParams(translated, params) : translated;
        if (log) {
        }
        return result;
    };

    return (
        <GlobalContext.Provider
            value={{
                router: rContext.router,
                locale: pageContext.locale || "",
                current: pageContext.route,
                locales: rContext.router.getLanguages(),
                route: pageContext,
                url,
                langFormat,
                t: f,
                getCountry: rContext.getCountry,
                server: rContext.server,
                gaTag: rContext.gaTag,
                pixelsTag: rContext.pixelsTag,
                fbDomainVerification: rContext.fbDomainVerification,
                isDevelop: rContext.isDevelop,
                isCurrentRoute,
                getImageFromName: rContext.getImageFromName
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};
GlobalContext.propTypes = {
    pageContext: PropTypes.object,
};
export default GlobalContext;
export {GlobalProvider};
