module.exports = {
    it: {
        path: 'prodotti/arredamento-di-interni',
        title: 'Complementi d\'arredo, arredamento di interni, tende da interno - Tappezzeria Orsilli Pisa',
        description: 'Vuoi dare un tocco di classe ai tuoi interni? Vieni a trovarci, ti consiglieremo la soluzione più adatta per le tue tende da interno e potrai scegliere tra la vasta gamma di tessuti a disposizione.'
    },
    en: {
        path: 'prodotti/arredamento-di-interni',
        title: 'Complementi d\'arredo, arredamento di interni, tende da interno - Tappezzeria Orsilli Pisa',
        description: 'Vuoi dare un tocco di classe ai tuoi interni? Vieni a trovarci, ti consiglieremo la soluzione più adatta per le tue tende da interno e potrai scegliere tra la vasta gamma di tessuti a disposizione.'
    },
    component: 'prodotti/arredamentointerni'
}
