module.exports = {
    it: {
        path: 'prodotti/pergole',
        title: 'Pergole Gibus - Tappezzeria Orsilli',
        description: 'Scopri i vari tipi di coperture esterne: pergole Gibus, vele ombreggianti, copertura autoportante con tessuto impermeabile, pergolato mobile, pergolato in alluminio. Sfrutta i tuoi spazi esterni con classe ed eleganza. Mettiamo a tua disposizione molteplici idee da giardino, contattaci per maggiori informazioni.'
    },
    en: {
        path: 'prodotti/pergole',
        title: 'Pergole Gibus - Tappezzeria Orsilli',
        description: 'Scopri i vari tipi di coperture esterne: pergole Gibus, vele ombreggianti, copertura autoportante con tessuto impermeabile, pergolato mobile, pergolato in alluminio. Sfrutta i tuoi spazi esterni con classe ed eleganza. Mettiamo a tua disposizione molteplici idee da giardino, contattaci per maggiori informazioni.'
    },
    component: 'prodotti/pergole'
}
