module.exports = {
    it: {
        path: 'installazioni',
        title: 'Le nostre installazioni - Tappezzeria Orsilli',
        description: 'Tappezzeria Orsilli mostra i lavori di artigianato e le installazioni che effettua presso i propri clienti. Contattaci per avere maggiori informazioni.'
    },
    en: {
        path: 'installazioni',
        title: 'Le nostre installazioni - Tappezzeria Orsilli',
        description: 'Tappezzeria Orsilli mostra i lavori di artigianato e le installazioni che effettua presso i propri clienti. Contattaci per avere maggiori informazioni.'
    }
}
