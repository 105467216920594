module.exports = {
    it: {
        path: 'cookie',
        title: 'Cookies - Tappezzeria Orsilli',
        description: 'Informativa sui cookies.'
    },
    en: {
        path: 'cookie',
        title: 'Cookies - Tappezzeria Orsilli',
        description: 'Informativa sui cookies.'
    },
    component: 'informative/cookie'
}
