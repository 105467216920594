module.exports = {
    it: {
        path: 'prodotti',
        title: 'Prodotti tappezzeria e Gibus - Tappezzeria Orsilli',
        description: 'Tappezzeria Orsilli offre una vasta gamma di prodotti affidandosi agli esperti del settore come Gibus, Tempotest, Simta ed altri ancora.'
    },
    en: {
        path: 'prodotti',
        title: 'Prodotti tappezzeria e Gibus - Tappezzeria Orsilli',
        description: 'Tappezzeria Orsilli offre una vasta gamma di prodotti affidandosi agli esperti del settore come Gibus, Tempotest, Simta ed altri ancora.'
    }
}
