module.exports = {
    it: {
        path: 'prodotti/pergole-bioclimatiche',
        title: 'Pergole Bioclimatiche - Tappezzeria Orsilli',
        description: 'Le pergole bioclimatiche Gibus sono la soluzione perfetta per creare un angolo di relax e benessere nel tuo terrazzo e nel tuo giardino. Comfort, Tecnogia e Design, 100% Made in Italy.'
    },
    en: {
        path: 'prodotti/pergole-bioclimatiche',
        title: 'Pergole Bioclimatiche - Tappezzeria Orsilli',
        description: 'Le pergole bioclimatiche Gibus sono la soluzione perfetta per creare un angolo di relax e benessere nel tuo terrazzo e nel tuo giardino. Comfort, Tecnogia e Design, 100% Made in Italy.'
    },
    component: 'prodotti/pergolebioclimatiche'
}
