import React, { useEffect, useState } from "react";
import Router from "../routes/router";
import { getImage } from "gatsby-plugin-image";

import find from "lodash/find"
import get from "lodash/get"

const RootContext = React.createContext();
const router = new Router();

const RootProvider = ({ children }) => {
  const getImageFromName = (images, name) => {
    const image =
      find(images || [], (img) => {
        return img.node.base === name;
      }) || {};
    if (!image) {
      return null;
    }
    return getImage(image.node);
  };
  const [server, setServer] = useState({});

  const isDevelop = () => process.env.GATSBY_ENV === "local";

  const getCountry = (serverFirst = true) => {
    return (
      serverFirst
        ? get(server, "country", router.getBrowserLanguage())
        : router.getBrowserLanguage()
    ).toLowerCase();
  };

  useEffect(() => {
    setServer({
      country: window.CloudFlareVisitorCountry || router.getBrowserLanguage(),
    });
  }, []);

  return (
    <RootContext.Provider
      value={{
        router,
        getCountry,
        server,
        isDevelop,
        getImageFromName,
          singleLang: router.getLanguages().length <= 1
      }}
    >
      {children}
    </RootContext.Provider>
  );
};

export default RootContext;
export { RootProvider };
