module.exports = {
    it: {
        path: 'prodotti/tende-da-sole',
        title: 'Tende da sole Gibus - Tappezzeria Orsilli Pisa',
        description: 'Scopri i vari tipi di tende da sole: Tende da sole per esterni Tempotest, tende da sole su misura, tende da sole Gibus. Tende a bracci, a caduta, in trazione, cappottine e schermature zip. Riparati dal sole con l\'eccellenza italiana! Gibus leader nel settore delle coperture solari per la tua casa o il tuo esercizio commerciale.'
    },
    en: {
        path: 'prodotti/tende-da-sole',
        title: 'Tende da sole Gibus - Tappezzeria Orsilli Pisa',
        description: 'Scopri i vari tipi di tende da sole: Tende da sole per esterni Tempotest, tende da sole su misura, tende da sole Gibus. Tende a bracci, a caduta, in trazione, cappottine e schermature zip. Riparati dal sole con l\'eccellenza italiana! Gibus leader nel settore delle coperture solari per la tua casa o il tuo esercizio commerciale.'
    },
    component: 'prodotti/tendedasole'
}
