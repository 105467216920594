const index  = require('./pages/index')
const contributi  = require('./pages/contributi')
const installazioni  = require('./pages/installazioni')
const contatti  = require('./pages/contatti')
const news  = require('./pages/news')
const prodotti  = require('./pages/prodotti')
const about  = require('./pages/about')
const tendedasole  = require('./pages/tendedasole')
const pergole  = require('./pages/pergole')
const pergolebioclimatiche  = require('./pages/pergolebioclimatiche')
// const veleombreggianti  = require('./pages/veleombreggianti')
const rivestimenti  = require('./pages/rivestimenti')
const arredamentointerni  = require('./pages/arredamentointerni')
const cookie  = require('./pages/cookie')
const privacy  = require('./pages/privacy')

module.exports = {
    about,
    prodotti,
    news,
    contatti,
    installazioni,
    contributi,
    index,
    tendedasole,
    pergole,
    pergolebioclimatiche,
    // veleombreggianti,
    rivestimenti,
    arredamentointerni,
    cookie,
    privacy,
}
