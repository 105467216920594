module.exports = {
    it: {
        path: 'prodotti/rivestimenti',
        title: 'Rivestimenti di mobili d\'arredo- Tappezzeria Orsilli Pisa',
        description: 'Scopri i nostri rivestimenti e gli interni: poltrone, rivestimento divani, divano con tessuto operato, rivestimento di poltrone e pouff, divano a due posti sfoderabile e divani rivestiti.'
    },
    en: {
        path: 'prodotti/rivestimenti',
        title: 'Rivestimenti di mobili d\'arredo- Tappezzeria Orsilli Pisa',
        description: 'Scopri i nostri rivestimenti e gli interni: poltrone, rivestimento divani, divano con tessuto operato, rivestimento di poltrone e pouff, divano a due posti sfoderabile e divani rivestiti.'
    },
    component: 'prodotti/rivestimenti'
}
