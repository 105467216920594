module.exports = {
    it: {
        path: 'contatti',
        title: 'Contatti - Tappezzeria Orsilli',
        description: 'Vuoi avere maggiori informazioni? In questa pagina puoi metterti in contatto con noi utilizzando il form, i nostri recapiti o puoi venire a trovarci in atelier, siamo a tua completa disposizione per rispondere alle tue domande!'
    },
    en: {
        path: 'contatti',
        title: 'Contatti - Tappezzeria Orsilli',
        description: 'Vuoi avere maggiori informazioni? In questa pagina puoi metterti in contatto con noi utilizzando il form, i nostri recapiti o puoi venire a trovarci in atelier, siamo a tua completa disposizione per rispondere alle tue domande!'
    }
}
