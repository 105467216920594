module.exports = {
    it: {
        path: 'chi-siamo',
        title: 'Chi siamo - Tappezzeria Orsilli Pisa',
        description: 'Grazie alla sapienza artigianale dei propri collaboratori, la tappezzeria Orsilli è in grado di soddisfare qualsiasi richiesta per la creazione di tendaggi interni su misura, ci occupiamo del montaggio dei prodotti Gibus su Pisa, San Giuliano Terme, Vecchiano e Migliarino e il resto della provincia di Pisa.'
    },
    en: {
        path: 'about',
        title: 'Chi siamo - Tappezzeria Orsilli Pisa',
        description: 'Grazie alla sapienza artigianale dei propri collaboratori, la tappezzeria Orsilli è in grado di soddisfare qualsiasi richiesta per la creazione di tendaggi interni su misura, ci occupiamo del montaggio dei prodotti Gibus su Pisa, San Giuliano Terme, Vecchiano e Migliarino e il resto della provincia di Pisa.'
    }
}
