
const pages = require('./pages')

const indexOf = require("lodash/indexOf")
const map = require("lodash/map")
const get = require("lodash/get")
module.exports = class Router {
    constructor(languages = ['it'], fallbackLocale = 'it') {
        this.languages = languages
        this.fallbackLocale = indexOf(this.languages, fallbackLocale) !== -1 ? fallbackLocale : 'it'
        this.current = {}
    }

    initRoutes () {
        this.routes = pages
    }

    getBrowserLanguage(ipLang= false) {
        if (ipLang && indexOf(this.getLanguages(), ipLang) !== -1) {
            return ipLang
        }

        if (typeof navigator === `undefined`) {
            return this.getFallbackLocale();
        }

        const lang = navigator && navigator.language && navigator.language.split("-")[0];
        if (!lang) {
            return this.getFallbackLocale();
        }

        if (indexOf(this.getLanguages(), lang) === -1) {
            return this.getFallbackLocale();
        }

        return lang
    }

    setCurrent(route) {
        this.current = route
    }

    getCurrent() {
        return this.current
    }

    getFallbackLocale() {
        return this.fallbackLocale
    }

    getLanguages() {
        return this.languages
    }

    getRoutes() {
        return this.routes
    }


    url(key, lang = null, log = false) {
        lang = lang || this.getFallbackLocale()
        const langs = this.getLanguages();
        const singleLang = langs.length <= 1;
        if (key === 'index') {
            return singleLang ? `/` : `/${lang}/`
        }
        const path = get(this.routes, `${key}.${lang}.path`, false)

        if (!path) {
            return '#'
        }
        return singleLang ? `/${path}/` : `/${lang}/${path}/`
    }

    addRoute(name, info, component = null) {
        this.routes[name] = info
        if (component) {
            this.routes[name].component = component
        }
    }

    cleanRoute(route) {
        return route.toLowerCase().replace(/ *\([^)]*\) */g, "").replace(/\s/g, '')
    }

    getRouteNodes() {
        return map(this.routes, (options, name) => {
            return {
                route: name,
                options: {
                    ...options,
                    component: options.component || name
                }
            }
        })
    }
}
