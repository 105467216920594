module.exports = {
    it: {
        path: 'news',
        title: 'News - Tappezzeria Orsilli',
        description: 'Per restare sempre aggiornato e scoprire in anteprima i nuovi arrivi, dai tessuti fino alle schermature solari, segui la nostra pagine delle news e vedrai tutto quello che il nostro atelier può offrire.'
    },
    en: {
        path: 'news',
        title: 'News - Tappezzeria Orsilli',
        description: 'Per restare sempre aggiornato e scoprire in anteprima i nuovi arrivi, dai tessuti fino alle schermature solari, segui la nostra pagine delle news e vedrai tutto quello che il nostro atelier può offrire.'
    }
}
