module.exports = {
    it: {
        path: '',
        title: 'Tappezzeria Orsilli – Atelier Gibus Pisa',
        description: 'Orsilli a Pisa propone: tende da interno e da esterno, tende da sole, pergole, pergole bioclimatiche Gibus, tutto realizzato 100% made in Italy e con sistemi d\'automazione e di motorizzazione a marchio CE.'
    },
    en: {
        path: '',
        title: 'Tappezzeria Orsilli – Atelier Gibus Pisa',
        description: 'Orsilli a Pisa propone: tende da interno e da esterno, tende da sole, pergole, pergole bioclimatiche Gibus, tutto realizzato 100% made in Italy e con sistemi d\'automazione e di motorizzazione a marchio CE.'
    }
}
