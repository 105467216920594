module.exports = {
    it: {
        path: 'contributi',
        title: 'Ecobonus 2022: Tende da Sole e Pergole sconti in fattura per tutti i tipi di schermature solari - Tappezzeria Orsilli Pisa',
        description: 'La legge di stabilità prevede una detrazione fino al 50% per l’acquisto delle schermature solari, tende esterne, tapparelle e oscuranti. Lavoriamo con i prodotti Gibus totalmente realizzati in Italia.'
    },
    en: {
        path: 'contributi',
        title: 'Ecobonus 2022: Tende da Sole e Pergole sconti in fattura per tutti i tipi di schermature solari - Tappezzeria Orsilli Pisa',
        description: 'La legge di stabilità prevede una detrazione fino al 50% per l’acquisto delle schermature solari, tende esterne, tapparelle e oscuranti. Lavoriamo con i prodotti Gibus totalmente realizzati in Italia.'
    }
}
